<template>
  <v-row :style="'width:' + ((collapsed ? 122 : 302) + (cellWidth * times.length)) + 'px;padding:0;margin:0'" class="topSticky">
    <v-col class="scheduleColumn header leftSticky" style="z-index:3 !important">
      <v-card :style="'width:' + (collapsed ? 120 : 300) + 'px'">
        <!-- <v-card-text>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small @click="$emit('collapseToggle')">
                <v-icon small left>fal fa-chevron-{{ collapsed ? 'right' : 'left' }}</v-icon>
                Room
              </v-btn>
            </template>
            <span>{{ collapsed ? 'Expand' : 'Collapse'}} the room labels</span>
          </v-tooltip>
        </v-card-text> -->
        <v-toolbar elevation="0">
          <v-toolbar-title>Room</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="settingsDialog" width="400">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :small="thin" icon>
                <v-icon :small="thin">fal fa-cog</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar>
                <v-toolbar-title>Calendar Settings</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="settingsDialog = false">
                  <v-icon>fal fa-times</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-switch v-model="collapsed" :label="collapsed ? 'Collapsed; Show Room Descriptions' : 'Expanded; Hide Room Descriptions'"></v-switch>
                <v-switch v-model="thin" :label="thin ? 'Taller Rows' : 'Shorter Rows'"></v-switch>
                <v-select v-model="startHour" :items="startHours" label="Set Start Hour" hide-details></v-select>
                <v-select v-model="endHour" :items="endHours" label="Set End Hour" hide-details></v-select>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </v-card>
    </v-col>
    <v-col v-for="time in times" :key="time" class="scheduleColumn header" :class="thin ? 'thin' : ''">
      <v-card :style="'width:' + (cellWidth - 2) + 'px'">
        <v-card-text>{{ time }}</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { ref, computed } from '@vue/composition-api'

export default {
  props: {
    times: {
      type: Array,
      required: true
    },
    cellWidth: {
      type: Number,
      default: 100
    }
  },
  setup (props, { root }) {
    const settingsDialog = ref(false)
    const collapsed = computed({
      get: () => root.$store.state.calendar.collapsed,
      set: (val) => root.$store.commit('calendar/setCollapsed', val)
    })
    const thin = computed({
      get: () => root.$store.state.calendar.thin,
      set: (val) => root.$store.commit('calendar/setThin', val)
    })
    const startHour = computed({
      get: () => root.$store.state.calendar.startHour,
      set: (val) => root.$store.commit('calendar/setStartHour', val)
    })
    const endHour = computed({
      get: () => root.$store.state.calendar.endHour,
      set: (val) => root.$store.commit('calendar/setEndHour', val)
    })

    const startHours = computed(() => {
      const arr = [
        { text: '12:00 AM', value: 0 }
      ]
      for (let i = 1; i < 24; i++) {
        if (endHour.value <= i) break
        const hour = (i <= 12 ? i : i - 12)
        const ampm = (i >= 12 && i < 24 ? 'PM' : 'AM')
        arr.push({ text: hour + ':00 ' + ampm, value: i })
      }
      return arr
    })
    const endHours = computed(() => {
      const arr = []
      for (let i = startHour.value + 1; i <= 24; i++) {
        const hour = (i <= 12 ? i : i - 12)
        const ampm = (i >= 12 && i < 24 ? 'PM' : 'AM')
        arr.push({ text: hour + ':00 ' + ampm, value: i })
      }
      return arr
    })

    return {
      settingsDialog,
      collapsed,
      thin,
      startHour,
      endHour,
      startHours,
      endHours
    }
  }
}
</script>
